<template>
    <tr class="funds-custom-row">
        <td tabindex="0" colspan="3">123</td>
        <td tabindex="0" colspan="3">444</td>
    </tr>
</template>

<script>
	export default {
		props: ['data', 'index'],
	}
</script>